<template>
  <div>
    <h2>Einstellungen für die Konferenzansicht</h2>
    <v-card class="mt-4 mb-4">
      <v-card-text>
        <v-row>
            <v-col cols="12" lg="6">
              <v-text-field
                v-model="$store.state.config.views[6].title"
                label="Titel" />
            </v-col>
            <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.views[6].icon"
              :items="icons"
              label="Icon"
              :prepend-icon="$store.state.config.views[6].icon"
            ></v-select>
          </v-col>
            <v-col cols="12" lg="6">
              <v-select
                v-model="$store.state.config.views[6].active"
                :items="states"
                label="Aktiv"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="6">
              <v-select
                v-model="version"
                :items="versions"
                label="Ansicht"
              ></v-select>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ConferenceSettings',
  data: () => ({
    icons: [
      { text: 'Auge', value: 'mdi-eye' },
      { text: 'Wahl', value: 'mdi-vote' },
      { text: 'Gruppe', value: 'mdi-account-group' },
      { text: 'Spalten', value: 'mdi-view-dashboard' },
      { text: 'Fragezeichen', value: 'mdi-account-question' },
      { text: 'Video', value: 'mdi-video' }
    ],
    states: [
      { text: 'Ja', value: true },
      { text: 'Nein', value: false }
    ],
    version: 'Nur aktuelle Konferenz',
    versions: ['Nur aktuelle Konferenz']
  })
}
</script>
