<template>
  <div>
    <h2>Einstellungen für die Ergebnisansicht</h2>
    <v-card class="mt-4 mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="$store.state.config.views[5].title"
              label="Titel" />
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.views[5].icon"
              :items="icons"
              label="Icon"
              :prepend-icon="$store.state.config.views[5].icon"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.views[5].active"
              :items="states"
              label="Aktiv"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.views[5].display"
              :items="displayOptions"
              label="Anzeige"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="$store.state.config.views[5].hashtag"
              label="Hashtag" />
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.views[5].hashtagClass"
              :items="hashtagClassOptions"
              label="Hashtagdarstellung"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.config.theme === 'custom'">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.text"
              label="Textfarbe" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.hashtag"
              label="Hashtagfarbe" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.bar"
              label="Balkenfarbe" />
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.fonts.bodyFontFamily"
              :items="fontFamilies"
              label="Schriftart"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.fonts.bodyTransform"
              :items="textTransforms"
              label="Schriftvariante"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.fonts.bodyFontWeight"
              :items="fontWeights"
              label="Schriftstärke"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="$store.state.config.fonts.bodyFontStyle"
              :items="fontStyles"
              label="Schriftstil"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ResultSettings',
  data: () => ({
    icons: [
      { text: 'Auge', value: 'mdi-eye' },
      { text: 'Wahl', value: 'mdi-vote' },
      { text: 'Gruppe', value: 'mdi-account-group' },
      { text: 'Spalten', value: 'mdi-view-dashboard' },
      { text: 'Liste', value: 'mdi-format-list-bulleted' },
      { text: 'Fragezeichen', value: 'mdi-account-question' },
      { text: 'Video', value: 'mdi-video' }
    ],
    states: [
      { text: 'Ja', value: true },
      { text: 'Nein', value: false }
    ],
    displayOptions: [
      { text: 'Stimmen und Prozente', value: 'default' },
      { text: 'Nur Stimmen', value: 'votes' },
      { text: 'Nur Prozente', value: 'percentages' }
    ],
    fontFamilies: [
      { text: 'BereitBold', value: 'BereitBold' },
      { text: 'Arvo', value: 'Arvo' },
      { text: 'Anton', value: 'Anton' },
      { text: 'PTSans', value: 'PTSans' }
    ],
    textTransforms: [
      { text: 'Normal', value: 'none' },
      { text: 'Großbuchstaben', value: 'uppercase' }
    ],
    fontWeights: [
      { text: 'Normal', value: 'normal' },
      { text: 'Fett', value: 'bold' }
    ],
    fontStyles: [
      { text: 'Normal', value: 'none' },
      { text: 'Kursiv', value: 'italic' }
    ],
    hashtagClassOptions: [
      { text: 'Horizontal', value: 'hashtag' },
      { text: 'Vertikal', value: 'hashtag rotated' },
      { text: 'Horizontal mit Schräge', value: 'hashtag slanted' }
    ]
  })
}
</script>
