<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    <GlobalSettings />
    <v-row>
      <v-col cols="12" lg="6">
        <ViewSettings :view=$store.state.config.views[1] :num=1 />
      </v-col>
      <v-col cols="12" lg="6">
        <ViewSettings :view=$store.state.config.views[2] :num=2 />
      </v-col>
      <v-col cols="12" lg="6">
        <ViewSettings :view=$store.state.config.views[3] :num=3 />
      </v-col>
      <v-col cols="12" lg="6">
        <ViewSettings :view=$store.state.config.views[4] :num=4 />
      </v-col>
      <v-col cols="12" lg="6">
        <ResultSettings />
      </v-col>
      <v-col cols="12" lg="6">
        <ConferenceSettings />
      </v-col>
    </v-row>
    <Actions />
  </div>
</template>

<script>

import GlobalSettings from '@/components/dashboard/GlobalSettings.vue'
import ViewSettings from '@/components/dashboard/ViewSettings.vue'
import ResultSettings from '@/components/dashboard/ResultSettings.vue'
import ConferenceSettings from '@/components/dashboard/ConferenceSettings.vue'
import Actions from '@/components/dashboard/Actions.vue'

export default {
  name: 'Dashboard',
  components: {
    GlobalSettings,
    ViewSettings,
    ResultSettings,
    ConferenceSettings,
    Actions
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>
