<template>
  <div class="elements">
    <h3 class="mb-2">Spalte {{ column + 1 }}</h3>
    <ul>
      <li>
        <input type="checkbox" value="currentPollTitle" id="currentPollTitle" v-model="$store.state.config.views[view].elements[column]">
        <label for="currentPollTitle">Aktueller Programmpunkt</label>
      </li>
      <li>
        <input type="checkbox" value="agenda" id="agenda" v-model="$store.state.config.views[view].elements[column]">
        <label for="agenda">Tagesordnung</label>
      </li>
      <li>
        <input type="checkbox" value="delegates" id="delegates" v-model="$store.state.config.views[view].elements[column]">
        <label for="delegates">Delegationen</label>
      </li>
      <li>
        <input type="checkbox" value="currentPoll" id="currentPoll" v-model="$store.state.config.views[view].elements[column]">
        <label for="currentPoll">Aktuelle Abstimmung</label>
      </li>
      <li>
        <input type="checkbox" value="comingPolls" id="comingPolls" v-model="$store.state.config.views[view].elements[column]">
        <label for="comingPolls">Anstehende Abstimmungen</label>
      </li>
      <li>
        <input type="checkbox" value="bylawMotions" id="bylawMotions" v-model="$store.state.config.views[view].elements[column]">
        <label for="bylawMotions">GO Anträge</label>
      </li>
      <li>
        <input type="checkbox" value="currentSpeeches" id="currentSpeeches" v-model="$store.state.config.views[view].elements[column]">
        <label for="currentSpeeches">Aktuelle Redebeiträge</label>
      </li>
      <li>
        <input type="checkbox" value="speechBoxes" id="speechBoxes" v-model="$store.state.config.views[view].elements[column]">
        <label for="speechBoxes">Redeboxen</label>
      </li>
      <li>
        <input type="checkbox" value="questions" id="questions" v-model="$store.state.config.views[view].elements[column]">
        <label for="questions">Fragen</label>
      </li>
      <li>
        <input type="checkbox" value="candidacies" id="candidacies" v-model="$store.state.config.views[view].elements[column]">
        <label for="candidacies">Kandidaturen</label>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Elements',
  props: {
    view: Number,
    column: Number
  }
}
</script>

<style lang="scss">
  .elements {
    ul {
      list-style: none;
      padding: 0;
    }

    label {
      margin-left: 0.5rem;
    }
  }
</style>
