<template>
  <div>
    <h2>Ansicht {{ num }}</h2>
    <v-card class="mt-4 mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="view.title"
              label="Titel" />
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="view.icon"
              :items="icons"
              label="Icon"
              :prepend-icon="view.icon"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="view.active"
              :items="states"
              label="Aktiv"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="view.cols"
              :items="columnOptions"
              label="Spalten"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="view.active">
          <v-col cols="12" lg="4">
            <Elements :view="num" :column=0 />
          </v-col>
          <v-col v-if="view.cols>1" cols="12" lg="4">
            <Elements :view="num" :column=1 />
          </v-col>
          <v-col v-if="view.cols>2" cols="12" lg="4">
            <Elements :view="num" :column=2 />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Elements from '@/components/dashboard/Elements.vue'

export default {
  name: 'ViewSettings',
  components: {
    Elements
  },
  props: {
    view: Object,
    num: Number,
    title: String
  },
  data: () => ({
    columnOptions: [1, 2, 3],
    icons: [
      { text: 'Auge', value: 'mdi-eye' },
      { text: 'Wahl', value: 'mdi-vote' },
      { text: 'Gruppe', value: 'mdi-account-group' },
      { text: 'Spalten', value: 'mdi-view-dashboard' },
      { text: 'Liste', value: 'mdi-format-list-bulleted' },
      { text: 'Fragezeichen', value: 'mdi-account-question' },
      { text: 'Video', value: 'mdi-video' }
    ],
    states: [
      { text: 'Ja', value: true },
      { text: 'Nein', value: false }
    ]
  })
}
</script>
