<template>
  <div class="actions mt-8">
    <div class="mt-2">
      <v-btn
        @click="save()"
        class="pa-6 mb-6 mr-6"
        title="Konfiguration im Browser speichern"
        dark>
        Änderungen Speichern
      </v-btn>
      <v-btn
        @click="resetLocal()"
        class="pa-6 mb-6 mr-6"
        title="Zuletzt gespeicherten Stand herstellen"
        dark>
        Änderungen Zurücksetzen
      </v-btn>
      <v-btn
        @click="resetDefault()"
        class="pa-6 mb-6 mr-6"
        title="Default-Konfiguration herstellen"
        dark>
        Auf Default Zurücksetzen
      </v-btn>
    </div>
    <h2>Konfigurationsdatei...</h2>
    <div class="mt-4">
      <input
        type="file"
        id="selectFiles"
        @change="checkFiles()"
        title="Select config file" />
    </div>
    <div class="mt-4">
      <v-btn
        @click="upload()"
        type="file"
        class="pa-6 mb-6 mr-6"
        :disabled="!hasFiles"
        :dark="hasFiles"
        title="Import config file">
        Hochladen
      </v-btn>
      <v-btn
        :href="dataString"
        download="config.json"
        class="pa-6 mb-6 mr-6"
        dark
        title="Download current configuration">
        Herunterladen
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Actions',
  data: () => ({
    hasFiles: false
  }),
  methods: {
    save () {
      localStorage.setItem('config', JSON.stringify(this.$store.state.config))
    },
    resetLocal () {
      const config = localStorage.getItem('config')
      if (config) {
        this.$store.commit('setConfig', JSON.parse(config))
      } else {
        this.$store.commit('setDefaultConfig')
      }
    },
    resetDefault () {
      localStorage.removeItem('config')
      this.$store.commit('setDefaultConfig')
    },
    checkFiles () {
      const files = document.getElementById('selectFiles').files
      if (files) {
        this.hasFiles = true
      }
    },
    upload () {
      var files = document.getElementById('selectFiles').files
      const fileReader = new FileReader()
      const store = this.$store
      fileReader.onload = function (e) {
        const result = JSON.parse(e.target.result)
        store.commit('setConfig', result)
      }
      fileReader.readAsText(files.item(0))
    }
  },
  computed: {
    dataString () {
      const config = JSON.stringify(this.$store.state.config)
      return 'data:text/json;charset=utf-8,' + encodeURIComponent(config)
    }
  }
}
</script>
