<template>
  <div>
    <h2>Globale Einstellungen</h2>
    <v-card class="mt-4 mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.title"
              label="Titel" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
              v-model="language"
              :items="languages"
              label="Sprache"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
              v-model="$store.state.config.theme"
              :items="themes"
              label="Theme"
              @change="updateTheme()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.config.theme === 'custom'">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.background"
              label="Hintergrundfarbe" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.headline"
              label="Überschriftfarbe" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="$store.state.config.colors.button"
              label="Buttonfarbe" />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="$store.state.config.fonts.headlineFontFamily"
              :items="fontFamilies"
              label="Überschriftart"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="$store.state.config.fonts.headlineTransform"
              :items="textTransforms"
              label="Überschriftvariante"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="$store.state.config.fonts.headlineFontWeight"
              :items="fontWeights"
              label="Überschriftstärke"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="$store.state.config.fonts.headlineFontStyle"
              :items="fontStyles"
              label="Überschriftstil"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'GlobalSettings',
  methods: {
    updateTheme () {
      this.$store.commit('setTheme')
    }
  },
  data: () => ({
    languages: ['Deutsch'],
    themes: [
      { text: 'Grüne', value: 'gruene' },
      { text: 'Bundesdelegiertenkonferenz 2022', value: 'bdk-22' },
      { text: 'Bundesdelegiertenkonferenz 2021', value: 'bdk-21' },
      { text: 'Bundesdelegiertenkonferenz 2020', value: 'bdk-20' },
      { text: 'Benutzerdefiniert', value: 'custom' }
    ],
    fontFamilies: [
      { text: 'BereitBold', value: 'BereitBold' },
      { text: 'Arvo', value: 'Arvo' },
      { text: 'Anton', value: 'Anton' },
      { text: 'PTSans', value: 'PTSans' }
    ],
    textTransforms: [
      { text: 'Normal', value: 'none' },
      { text: 'Großbuchstaben', value: 'uppercase' }
    ],
    fontWeights: [
      { text: 'Normal', value: 'normal' },
      { text: 'Fett', value: 'bold' }
    ],
    fontStyles: [
      { text: 'Normal', value: 'none' },
      { text: 'Kursiv', value: 'italic' }
    ],
    language: 'Deutsch'
  })
}
</script>
